import React from "react";
import TopNewNav from "../../moleculars/TopNewNav";
import PaddingPwa from "../../moleculars/PaddingPwa";
import legalitas from "../../../assets/images/new pwa icon/dashboard/iconLegalitas.svg";
import pengamanan from "../../../assets/images/new pwa icon/dashboard/iconPengamananUsaha.svg";
import comprehensive from "../../../assets/images/new pwa icon/dashboard/iconComprehensive.svg";
import konsultasi from "../../../assets/images/new pwa icon/dashboard/iconKonsultasi.svg";
import pendampingan from "../../../assets/images/new pwa icon/dashboard/iconPendampingan.svg";
import pengamananPribadi from "../../../assets/images/new pwa icon/dashboard/iconPengamananPribadi.svg";
import dekaLegal from "../../../assets/images/new pwa icon/dashboard/iconDekaLegalMerah.svg";
import NavigateButtomNew from "../../moleculars/NavigateButtomNew";
import Gap from "../../moleculars/Gap";
import TitleHeader from "../../utils/TitleHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Dashboard() {
  TitleHeader("Halaman dashboard");
  var user = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <TopNewNav
        path={`/dashboard`}
        type="dashboard"
        background="transparent"
      />

      <div className="container-class">
        <div className="responsive-class">
          <div className="res-class">
            <div className="container-user-fg">
              <div className="name-user-fj">
                {t("greeting")}, {user?.mmbr_name} 👋
              </div>
            </div>
            <div className="dashboard-container-f">
              <div className="column-flex" onClick={() => navigate("/business-or-legal-entity")}>
                <img src={dekaLegal} alt="deka legal"/>
                <div className="card-font">
                  <span className="card-title">DEKA LEGAL</span>
                  <div className="card-divider"/>
                  <p className="card-desc">
                    {t("deka_desc")}
                  </p>
                </div>
              </div>
              <Gap height={70} />
              <NavigateButtomNew />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
