import React from "react";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "../../assets/css/TimePickerStyles.css";

function TimePickerComponent({ value, onChange }) {
  return <TimePicker onChange={onChange} value={value} />;
}

export default TimePickerComponent;
